<template>
  <div v-if="courses.length > 0" class="row q-pt-lg">
    <div
      v-for="course in courses"
      :key="`xs-${course.id}`"
      class="col-12 col-md-3"
    >
      <course-item
        :course="course"
        :tab="'notEnrolled'"
        :key="`courses-item-${course.id}`"
      />
    </div>
  </div>
  <no-content v-else class="q-mt-lg" />

  <div class="q-pa-lg flex flex-center">
    <pagination
      :disabled="in_request"
      :resource="pagination"
      :repository="fetchCoursesAction"
    />
  </div>
</template>

<script>
import CourseItem from "@/modules/main/pages/courses/components/CourseItem";
import NoContent from "@/modules/main/pages/courses/components/no-content";
import Pagination from "@/modules/main/components/pagination";

import { createNamespacedHelpers } from "vuex-composition-helpers";
import { onMounted } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("courses");

export default {
  name: "CoursesNotEnrolled",

  components: { NoContent, Pagination, CourseItem },

  setup() {
    const { fetchCoursesAction } = useActions(["fetchCoursesAction"]),
      { courses, in_request, pagination } = useGetters({
        courses: "getCourses",
        in_request: "getInRequest",
        pagination: "getPaginationResource",
      });

    onMounted(() => fetchCoursesAction());

    return {
      courses,
      in_request,
      pagination,
      fetchCoursesAction,
    };
  },
};
</script>

<style lang="scss">
.courses-wrapper {
  margin: 0 auto;
}
.course-display {
  width: 100%;
  max-width: 300px;
  height: 400px;
  margin: 5px 5px;
}
.mobile {
  .course-display {
    max-width: fit-content;
    margin: 0;
    .course-display {
      width: 100%;
      height: 400px;
      margin: 5px 0;
    }
  }
}
</style>
