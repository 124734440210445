<template>
  <q-input
    dense
    outlined
    v-model="search"
    type="search"
    debounce="1000"
    class="input-search"
  >
    <template v-slot:append>
      <q-icon name="search" />
    </template>
  </q-input>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { nextTick, ref, watch } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("courses");

export default {
  name: "SearchFilter",

  setup() {
    const clearWatch = ref(0),
      search = ref(null);

    const { badges_cleaned, filter } = useGetters({
        badges_cleaned: "getBadgeChanges",
        filter: "getFilter",
      }),
      { setSearchFilerAction } = useActions(["setSearchFilerAction"]);

    watch(badges_cleaned, () => {
      nextTick(() => {
        if (!filter.value.q) clearWatch.value++;
      });
    });

    watch(search, (cv) => {
      if (!cv) setSearchFilerAction(null);
      else setSearchFilerAction(cv);
    });

    return { search, clearWatch };
  },
};
</script>

<style lang="scss" scoped>
.input-search {
  width: 350px;
}
@media screen and (max-width: 800px) {
  .input-search {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
