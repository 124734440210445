<template>
  <div
    class="flex flex-center paginator-component q-pa-lg "
    v-if="resource.last_page > 1"
  >
    <q-pagination
      color="black"
      v-model="current"
      :disable="disabled"
      @update:model-value="onUpdatedValue"
      :max-pages="$q.platform.is.mobile ? 7 : 10"
      :max="resource.last_page"
      direction-links
    />
  </div>
</template>

<script setup>
import { ref, defineComponent, defineProps, watch } from "vue";

defineComponent({
  name: "Pagination"
})

const props = defineProps({
  disabled: {
    type: Boolean,
  },
  resource: {
    type: Object,
  },
  repository: {
    type: Function,
  },
})

const current = ref(props.resource.current_page);

const onUpdatedValue = (value) => {
  props.repository({ page: value });
};

/* Sincroniza a página atual com props.resource.current_page sempre que mudar */
watch(
  () => props.resource?.current_page,
  (newPage) => {
    current.value = newPage;
  }
);
</script>

<style lang="scss">
.mobile {
  .paginator-component {
    padding-right: 0;
    padding-left: 0;
    .q-pagination {
      .q-btn-item {
        font-size: 12px;
      }
    }
  }
}
</style>
