import script from "./pagination.vue?vue&type=script&setup=true&lang=js"
export * from "./pagination.vue?vue&type=script&setup=true&lang=js"

import "./pagination.vue?vue&type=style&index=0&id=5bf43ac2&lang=scss"

const __exports__ = script;

export default __exports__
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPagination,QBtn});
