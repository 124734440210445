<template>
  <q-input
    dense
    outlined
    ref="inputRef"
    debounce="1000"
    v-model="value"
    prefix="Valor até: "
    class="input-price"
    :error="!!errorMessage"
    :error-message="errorMessage"
    @keyup="() => (shape = false)"
    @update:model-value="setFilterValue"
  />
  <div class="">
    <q-checkbox
      right-label
      v-model="shape"
      class="q-mb-md"
      :class="{
        'q-ml-lg': $q.screen.width >= 920,
      }"
      @update:model-value="onShapeChange"
      :label="$t('course.only_free_courses')"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { parse, useCurrencyInput } from "vue-currency-input";
import { nextTick, watch, ref } from "vue";
import { useField } from "vee-validate";

const { useGetters, useActions } = createNamespacedHelpers("courses");

export default {
  name: "PriceFilter",

  setup() {
    const options = { currency: "BRL", locale: "br" },
      { inputRef } = useCurrencyInput(options);

    const { badges_cleaned, filter } = useGetters({
        badges_cleaned: "getBadgeChanges",
        filter: "getFilter",
      }),
      { setPriceFilerAction } = useActions(["setPriceFilerAction"]);

    const { errorMessage, value } = useField("amount", validateMinValue),
      setFilterValue = (value) => {
        let v = parse(value, options);
        if (v > 0) setPriceFilerAction(parse(value, options));
        else setPriceFilerAction(null);
      };

    watch(badges_cleaned, () => {
      nextTick(() => {
        if (filter.value.value === null) {
          value.value = null;
          if (shape.value === true) {
            shape.value = false;
          }
        }
      });
    });

    const validateMinValue = (value) =>
        parse(value, options) < 0 ? "Não pode ser menor que zero" : true,
      onShapeChange = (v) => {
        value.value = null;
        if (v) setPriceFilerAction(0);
        else setPriceFilerAction(null);
      },
      shape = ref(false);

    return {
      value,
      shape,
      inputRef,
      errorMessage,
      setFilterValue,
      onShapeChange,
    };
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 920px) {
  .input-price {
    width: 100%;
  }
}
</style>
