<template>
  <div class="content-courses">
    <div class="q-pa-sm bg-white default-card-border default-rounded">
      <div
        class="row"
        :class="{
          'no-wrap justify-between': $q.screen.width > 800,
          'justify-center': $q.screen.width <= 800,
        }"
      >
        <q-tabs
          dense
          no-caps
          align="left"
          v-model="tab"
          narrow-indicator
          @update:model-value="onTabChange"
        >
          <q-tab
            :key="i"
            :ripple="false"
            :name="tab.name"
            :label="tab.label"
            v-for="(tab, i) in coursesTabs()"
          />
        </q-tabs>
        <search-filter
          v-if="tab === 'notEnrolled'"
          :class="{
            'q-mt-lg full-width q-pr-sm q-pl-sm': $q.screen.width <= 800,
          }"
        />
      </div>
      <q-expansion-item
        dense
        icon="filter_alt"
        label="Mais filtros"
        v-model="expandFilters"
        v-if="tab === 'notEnrolled'"
        class="q-mt-sm q-pb-none q-pt-md"
        :class="{
          'q-pa-xs': $q.screen.width <= 800,
          'q-pr-sm q-pl-sm': $q.screen.width > 800,
        }"
      >
        <div class="row q-mt-md">
          <filter-category
            :class="{
              'q-mt-sm': $q.screen.width <= 800,
              'q-mr-sm': $q.screen.width > 800,
            }"
          />
          <price-filter
            :class="{
              'q-mt-sm': $q.screen.width <= 800,
              'q-mr-sm': $q.screen.width > 800,
            }"
          />
        </div>
      </q-expansion-item>
    </div>
    <badges />
    <q-tab-panels class="bg-transparent" v-model="tab" animated>
      <q-tab-panel
        :key="i"
        :name="tab.name"
        class="no-scroll no-padding"
        v-for="(tab, i) in coursesTabs()"
      >
        <component :is="tab.component" />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import CoursesNotEnrolled from "./components/CoursesNotEnrolled";
import CoursesInProgress from "./components/CoursesInProgress";
import CoursesFinished from "./components/CoursesFinished";
import FilterCategory from "./components/FilterCategory";
import SearchFilter from "./components/SearchFilter";
import PriceFilter from "./components/PriceFilter";
import Badges from "./components/badges";

import { createNamespacedHelpers } from "vuex-composition-helpers";
import { useRoute } from "vue-router";
import { coursesTabs } from "./setup";
import { ref, onMounted } from "vue";

const { useActions } = createNamespacedHelpers("courses");

export default {
  components: {
    Badges,
    PriceFilter,
    SearchFilter,
    FilterCategory,
    CoursesFinished,
    CoursesInProgress,
    CoursesNotEnrolled,
  },


  setup() {
    const route = useRoute(),
      tab = ref(route.query.tab || "notEnrolled"),
      expandFilters = ref(false);

    const { fetchCategoriesAction, resetAllFilterAction } = useActions([
      "fetchCategoriesAction",
      "resetAllFilterAction",
    ]);

    const onTabChange = (tab) => {
      expandFilters.value = false;
      resetAllFilterAction(tab);
    };

    onMounted(() => fetchCategoriesAction());

    return {
      tab,
      expandFilters,
      coursesTabs,
      onTabChange,
    };
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 800px) {
  .content-courses {
    margin: 0 -15px;
  }
}
</style>
