<template>
  <div class="row q-mt-md" v-if="badges.length">
    <q-badge
      v-for="(badge, key) in badges"
      class="bg-white q-mr-sm q-pa-xs default-rounded"
      rounded
      :key="key"
    >
      <div class="row q-pr-sm q-pl-sm items-center justify-between">
        <span class="text-black">{{ badge.label }}</span>
        <q-btn
          flat
          dense
          outline
          unelevated
          size="1em"
          color="red"
          icon="close"
          :ripple="false"
          @click="remove(badge)"
        />
      </div>
    </q-badge>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("courses");

export default {
  name: "badges",

  setup() {
    const model = ref(null);

    const { badges } = useGetters({
        badges: "inFilterBadges",
      }),
      { clearBadgeAction } = useActions(["clearBadgeAction"]);

    const remove = (badge) => clearBadgeAction(badge);

    return {
      model,
      badges,
      remove,
    };
  },
};
</script>
