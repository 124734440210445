<template>
  <div v-if="courses.length > 0" class="row q-pt-lg">
    <div
      v-for="course in courses"
      :key="`xs-${course.id}`"
      class="col-12 col-md-3"
    >
      <course-item
        :course="course"
        :tab="'finish'"
        :key="`courses-item-${course.id}`"
      />
    </div>
  </div>
  <no-content v-else class="q-mt-lg" />
</template>

<script>
import CourseItem from "@/modules/main/pages/courses/components/CourseItem";
import NoContent from "@/modules/main/pages/courses/components/no-content";
import { createNamespacedHelpers } from "vuex-composition-helpers";

const { useGetters, useActions } = createNamespacedHelpers("courses");

export default {
  name: "CoursesFinished",

  components: { NoContent, CourseItem },

  setup() {
    const { fetchCoursesFinishedAction } = useActions([
        "fetchCoursesFinishedAction",
      ]),
      { courses, in_request, pagination } = useGetters({
        courses: "getCoursesFinished",
        in_request: "getInRequest",
        pagination: "getFinishedPaginationResource",
      });

    fetchCoursesFinishedAction();

    return {
      courses,
      in_request,
      pagination,
      fetchCoursesFinishedAction,
    };
  },
};
</script>
