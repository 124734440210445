<template>
  <div class="full-width">
    <div class="bg-white q-pa-lg default-rounded full-width">
      <q-parallax>
        <template v-slot:content="scope">
          <div
            class="absolute column items-center"
            :style="{
              opacity: 0.45 + (1 - scope.percentScrolled) * 0.55,
              top: scope.percentScrolled * 60 + '%',
              left: 0,
              right: 0,
            }"
          >
            <div
              class="text-center text-weight-thin"
              :class="{
                'text-h4': $q.screen.width > 800,
                'text-h5': $q.screen.width <= 800,
              }"
            >
              {{ $t("course.no_courses") }}
            </div>
          </div>
        </template>
      </q-parallax>
    </div>
  </div>
</template>

<script>
export default {
  name: "no-content",
};
</script>
