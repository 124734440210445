<template>
  <q-select
    dense
    outlined
    multiple
    v-model="model"
    :options="options"
    option-label="category"
    class="input-category"
    @update:model-value="setFilterValue"
    :label="$t('course.category')"
  />
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, watch, nextTick } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("courses");

export default {
  name: "FilterCategory",

  setup() {
    const model = ref(null);

    const { options, filter, badges_cleaned } = useGetters({
        options: "getCategories",
        filter: "getFilter",
        badges_cleaned: "getBadgeChanges",
      }),
      { setFilterCategoriesAction } = useActions(["setFilterCategoriesAction"]);

    const setFilterValue = (categories) => {
      setFilterCategoriesAction(
        categories.length ? categories.map((category) => category.id) : null
      );
    };

    watch(badges_cleaned, () => {
      nextTick(() => {
        if (filter.value.categories && model.value) {
          model.value.forEach((category, index) => {
            if (filter.value.categories.indexOf(category.id) === -1)
              model.value.splice(index, 1);
          });
        } else model.value = null;
      });
    });

    return {
      model,
      options,
      setFilterValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-category {
  width: 250px;
}

@media screen and (max-width: 920px) {
  .input-category {
    width: 100%;
    margin-bottom: 15px;
  }
}
</style>
